import type { Language } from '@packages/config';
import config from '@packages/config';
import { useCustomer } from '@packages/utilities';
import type { KimIFrameQueryType, KimPageTypeProps, KimRatingDialogProps } from './KimRating.types';
import { useI18n } from '../../hooks';

/**
 * Represents the template data for the useIFrameUrl function.
 */
const templateData: {
  [key in KimPageTypeProps]: {
    id: KimIFrameQueryType['x03'];
    title: KimIFrameQueryType['x05'];
    source: KimIFrameQueryType['x07'];
    iSurveyId: KimIFrameQueryType['i_survey'];
  };
} = {
  storefront: {
    id: '22',
    title: 'BBSTART',
    source: '55',
    iSurveyId: config.customerCentricFeedback?.surveyIdStorefront,
  },
  detailpage: {
    id: '23',
    title: 'BBDETAIL',
    source: '56',
    iSurveyId: config.customerCentricFeedback?.surveyIdDetailview,
  },
  search: {
    id: '24',
    title: 'BBPL',
    source: '57',
    iSurveyId: config.customerCentricFeedback?.surveyIdSearch,
  },
};

/**
 * Formats the current date to YYYYMMDDHHMM.
 */
const formattedDate = () => {
  const date = new Date();
  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'), // Months are 0-indexed in JavaScript
    String(date.getDate()).padStart(2, '0'),
    String(date.getHours()).padStart(2, '0'),
    String(date.getMinutes()).padStart(2, '0'),
  ].join('');
};

const countryId = () => {
  const {
    i18n: { defaultLocale },
  } = config;
  switch (defaultLocale.substring(3)) {
    case 'AT':
      return '10';
    case 'CH':
      return '20';
    default:
      return '30';
  }
};

/**
 * Custom hook for building the search query for the KIM-iFrame call.
 */
const useBuildSearchQuery = ({ ratingValue, pageType, templateParams }: KimRatingDialogProps) => {
  const { customer } = useCustomer();
  const { language, defaultLanguage } = useI18n();
  const currentLanguage = (language || defaultLanguage) as Language;

  const kid = `${customer?.extra?.kid ? parseInt(customer?.extra?.kid, 10) : 0}`;

  const searchParams: KimIFrameQueryType = {
    i_survey: templateData[pageType].iSurveyId,
    x02: `${ratingValue}`,
    x03: templateData[pageType].id,
    x04: formattedDate(),
    x05: templateData[pageType].title,
    x06: kid,
    x07: templateData[pageType].source,
    x08: countryId(),
    x09: currentLanguage === 'fr' ? '222' : '111',
    x10: config.customerCentricFeedback.tenantId,
    x11: 'x',
    x12: 'x',
    x13: 'x',
    x14: '',
    x15: 'x',
    x16: 'x',
    x17: 'x',
    x18: 'x',
    x19: 'x',
    x20: 'x',
    x21: 'x',
    QSL: currentLanguage,
    callback: '',
    ...templateParams,
  };

  // build checksum - sum of selected arguments plus fixed salt 442698
  searchParams.x14 = [
    searchParams.x02,
    searchParams.x03,
    searchParams.x06,
    searchParams.x07,
    searchParams.x08,
    searchParams.x09,
    searchParams.x10,
    // searchParams.x13, -> with KIM-50 this becomes an x which cant be used for checksum
  ]
    .reduce((accumulator, currentValue) => accumulator + parseInt(currentValue, 10), 442698)
    .toString();
  return searchParams;
};

/**
 * Custom hook to generate the URL for an iframe.
 * @param options - The options for generating the URL.
 * @returns The generated iframe URL.
 */
export const useIFrameUrl = (dialogProps: KimRatingDialogProps) => {
  const searchParams = useBuildSearchQuery(dialogProps);

  const iFrameUrl = new URL(`https://d438.keyingress.de/`);
  iFrameUrl.search = decodeURIComponent(new URLSearchParams(searchParams).toString());
  return iFrameUrl.href;
};
